
:root {
  --main-color: #0F4DA8;
  --main-color-transparent: rgb(15, 77, 168, 0.4);
  --second-color: #00A383;
  --third-color: #ffa100;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: rgba(15, 77, 168, 0.1);
  --neutral-color: #fff;
  --bg-selected: rgba(15, 77, 168, 0.2);
}

* {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}
.nav-header {
  color: white;
}

.nav-header:hover {
  color: var(--third-color);
}

.nav-header.active {
  color: var(--third-color);
}

.nav-header.active:hover{
  color:white;
}

.nav-header-dropdown {
  color: black;
}

.nav-header-quality {
  color: white;
}

.markdown-body {
  background-color: var(--second-color);
  color: white;
  padding: 16px;
  cursor: pointer;
}
